import * as React from 'react'
import DuckPage from '../../components/duckPage'
import {
	Button,
	Flex,
	Text,
	Heading,
	useToast,
	Container
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { updateRFID, validateShortCode } from '../../services/rfid'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import RfidCard from '../../icons/rfid-card.svg'
import { object, string } from 'yup'
import {
	getTempGroup,
	removeRFID,
	setRFID,
	getRFID,
	setTempTeam,
	removeEventId
} from '../../helpers/database'
import { navigate } from 'gatsby'
import RFIDConnectStatus from '../../components/RFIDConnectStatus'
import { useAuth } from '../../hooks/useAuth'
import Input from '../../components/input'
import eventTracking from '../../services/eventTracking'
// import { getGroupTeams, joinTeam } from '../../services/team'
import QRScanner from '../../components/QRScanner'
import { errorToastOptions } from '../../helpers'

const LinkRFIDPage = ({ cardId, basePath = 'login', onClose, inVenue }) => {
	const { profile, refreshProfile } = useAuth()
	const [validating, setValidate] = useState(false)
	const resolver = yupResolver(
		object()
			.shape({
				code: string().trim().required().min(4)
			})
			.required()
	)
	const {
		watch,
		register,
		handleSubmit,
		getValues,
		setError,
		clearErrors,
		setValue,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false
	})
	const watchCode = watch('code')
	const [editing, setEditing] = useState(false)

	const group = getTempGroup()
	const toast = useToast()
	// const groupTeamsQuery = useQuery(['group', group?.id], getGroupTeams)

	const isValid = !!watchCode
	const { fetchStatus, data, isFetching } = useQuery(
		['code', watchCode?.trim().toUpperCase()],
		validateShortCode,
		{
			enabled: validating && !!watchCode?.trim()
		}
	)

	const updateRFIDMutation = useMutation(updateRFID, {
		onSuccess: async (data) => {
			await refreshProfile()
			// setTempTeam(groupTeamsQuery?.data[0])
			setEditing(false)
			await navigate(`${basePath}/ready-to-play/`)
		},
		onError: (error) => {
			setEditing(false)
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	// const joinTeamMutation = useMutation(joinTeam, {
	// 	onSuccess: async (data) => {
	// 		// Joined team
	// 		const rfidData = getRFID()

	// 		updateRFIDMutation.mutate({
	// 			teamId: groupTeamsQuery?.data[0].id,
	// 			rfid: rfidData.cardId
	// 		})
	// 	},
	// 	onError: (error) => {
	// 		toast({
	// 			description: error.message || error,
	// 			...errorToastOptions
	// 		})
	// 	}
	// })

	const onValidateCode = async () => {
		const data = getValues()
		if (!data?.code) return
		setValidate(true)
	}

	const onNewScanResult = (decodedText) => {
		// handle decoded results here

		let code
		if (decodedText.toString().includes('http')) {
			code = decodedText.split('cardid=')[1]
		} else {
			code = decodedText
		}

		code = code.replace('<', '').replace('>', '')

		setValue('code', code)
		onValidateCode()
	}

	const onSubmit = (data) => {
		setRFID(data)
		setEditing(true)

		// if (groupTeamsQuery?.data) {
		// 	joinTeamMutation.mutate({
		// 		id: group?.id,
		// 		teamId: groupTeamsQuery?.data[0].id
		// 	})
		// }

		if (group?.id) {
			updateRFIDMutation.mutate({
				groupId: group.id,
				rfid: data.cardId
			})
		}
	}

	useEffect(() => {
		if (isFetching) {
			clearErrors('code')
			setValidate(false)
		} else {
			if (!!data?.cardId) {
				clearErrors('code')
				setValidate(false)
				onSubmit(data)
			} else if (!!data) {
				setValidate(false)
				setError('code', {
					type: 'custom',
					message: 'Oh no! That code is invalid'
				})
				eventTracking('errors_RFID_read', {
					user_id: profile?.id,
					RFID: watchCode
				})
			}
		}
	}, [data, fetchStatus, isFetching])

	if (!!cardId && cardId !== 'null') {
		setRFID({ cardId })
		// return <SelectTeamPage />
	}

	if (!group) {
		navigate(`${basePath}/event-code`)
	}

	return (
		<form onSubmit={handleSubmit(onValidateCode)}>
			<DuckPage
				theme="dark"
				topText="You're in..."
				topLargeText={group?.name}
				// backBtn={-1}
				// onBack={() => {
				// 	console.log('go back')
				// 	// Clear event id, so user can type in new one
				// 	removeEventId()
				// 	navigate('/in-venue-onboarding/event-code')
				// }}
				access={1}
				fullWidthActionBtns
				noHeader={false}
				hideSidebar={inVenue}
				primaryBtn={
					<Button
						variant="primary"
						isLoading={editing}
						disabled={!isValid || isFetching}
						type="submit"
					>
						Continue
					</Button>
				}
			>
				<Flex justifyContent="center" mt="10px">
					<Heading as="h1" color={'white'}>
						Now link
						<br />
						your wristband
					</Heading>
				</Flex>
				{/* <Flex justifyContent="center" alignItems="center" mt="40px">
					<RfidCard /> */}
				{/* <StaticImage
						style={{ width: 160, height: '' }}
						alt="Card"
						src="../../images/rfid-card.svg"
					/> */}
				{/* </Flex> */}
				<Container maxWidth={'80%'} pt={'70%'} position={'relative'}>
					<Container
						position={'absolute'}
						top={0}
						left={0}
						height={'100%'}
						width={'100%'}
					>
						<QRScanner qrCodeSuccessCallback={onNewScanResult} />
					</Container>
				</Container>
				<Input
					name="code"
					type="code"
					variant="filled"
					placeholder="RFID Code"
					mt="40px"
					register={register}
					errors={errors}
				/>
				<Text variant="m" mt="10px" color="white" textAlign="center">
					Tip: Find your RFID code below the QR code on your
					wristband.
				</Text>
			</DuckPage>
		</form>
	)
}

export default LinkRFIDPage
